<template>
	<el-menu class="tree-menu" :background-color="menuBgColor" text-color="#000" active-text-color="#3A84AB" :default-openeds='["1"]' menu-trigger="click">

		<el-submenu index="1" @click="pMenuClick(menuId)">
			<template slot="title" >
				<i class="el-icon-folder" style="font-size: 1.375rem;margin-right: 0.625rem;"></i>
				<span class="hidden" style="font-size: 1.375rem;">{{title}}</span>
			</template>
			<Menutree :treeList="treeList"></Menutree>
		</el-submenu>

	</el-menu>
</template>

<script>
	import Menutree from './treeMenuList.vue'
	export default {
		name: "treeMenu",
		components: {
			Menutree
		},
		props: {
			title: {
				type: String,
				default: ""
			},
			menuBgColor: {
				type: String,
				default: '#fff'
			},
			treeList: {
				type: Array,
				default: []
			}
		},
		data() {
			return {
				menuId: sessionStorage.getItem("menuId")
			}
		},
		methods: {
			pMenuClick(id) {
				console.log(id)
				this.$bus.$emit("pMenuClick",id)
			}
		}
	}
</script>

<style lang="scss">
.el-submenu__icon-arrow {
  color: #b4b8c5;
}
</style>
<style lang="scss" scoped>
.tree-menu {
	width: 100%;
	height: 100%;
}
</style>
