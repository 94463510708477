<template>
	<div class="process-file">
		<div class="container">
			<div class="title">
				<span class="icon1">
					<i :class="menuIcon"></i>
				</span>
				<span class="txt">{{menuName}}</span>
			</div>
			<div class="input-box">
				<!-- <el-input
					placeholder="请输入内容"
					prefix-icon="el-icon-search"
					v-model="searchInput"
					style="width: 300px !important;"
					@keyup.enter.native="searchFile()"
					clearable>
				</el-input> -->
				<el-button type="primary" @click="confirmAdd()">确 认</el-button>
				<el-button type="infor" @click="toggleSelection()">取 消</el-button>
				<el-button type="danger" @click="backout()">撤 销</el-button>
			</div>
			<div class="table-box" v-if="treeList && treeList.length > 0" >
				
				<div class="treeList-box">
					<TreeMenu :treeList="treeList" :title="menuName"></TreeMenu>
				</div>
				
				<div class="table">
					<el-table 
						class="table1"
						ref="table1"
						:data="tableData"
						max-height="650"
						:row-class-name="tableRowClassName"
						@selection-change="handleSelectionChange"
						style="width: 100%;">
						<el-table-column type="selection" min-width="5%" align="center"></el-table-column>
						<el-table-column label="名称" prop="name" min-width="60%"></el-table-column>
						<el-table-column label="版本" prop="version" min-width="20%" align="center"></el-table-column>
						<el-table-column align="center" min-width="15%" label="操作">
							<!-- <template slot="header" slot-scope="scope" v-if="isBtnShow">
						        <el-button type="primary" @click="changeFile()"> 切 换  </el-button>
							</template> -->
							<template slot-scope="scope">
								<el-button type="primary" @click="watchBtnClick(scope.row.fileUrl)" >
									查 看
								</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div> 
			<el-table
				v-else
				class="table2"
				:data="tableData"
				ref="table"
				height="150"
				:row-class-name="tableRowClassName"
				:height="tableHeight">
				<el-table-column label="名称" prop="name" min-width="60%"></el-table-column>
				<el-table-column label="版本" prop="version" min-width="20%" align="center"></el-table-column>
				<el-table-column align="center" min-width="20%" label="操作">
					<!-- <template slot="header" slot-scope="scope">
				        <el-button type="primary" @click="changeFile()"> 切 换 </el-button>
					</template> -->
					<template slot-scope="scope">
						<el-button @click="watchBtnClick(scope.row.fileUrl)" type="primary">
							查 看
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		
	</div>
</template>

<script>
import TreeMenu from "./TreeMenu.vue"
import {
	getFileDatas,
	getProcessFileTableData,
	getProcessFileByFileName,
	changeFileByScan
} from 'network/processFile'

export default {
	name: "processFile",
	data() {
		return {
			treeList: [],
			tableData: [],
			fileId: "",
			searchInput: "",//搜索框
			id: sessionStorage.getItem("menuId"),
			tableHeight: 500,
			isTableShow: false,
			isBtnShow: true,
			baseUrl: sessionStorage.getItem("baseUrl"),
			imgUrl: '',
			cardNoInput: '',
			remainInput: '',
			isRight: true,
			timer: null,
			multipleSelection: []
		}
	},
	props: {
		menuIcon: {
			type: String,
			default: ""
		},
		menuName: {
			type: String,
			default: ""
		}
	},
	destroyed() {
		if(this.timer) {
			clearInterval(this.timer); //关闭
		}  //利用vue的生命周期函数
	},
	created() {
		
		this.getFileDatas1(this.id);
		this.getTableData1(this.id);
		//点击菜单项
		this.$bus.$on("getFileData", (id, name) => {
			this.getFileDatas1(id);
			this.getTableData1(id);
		})
		//监听一级菜单（文件夹）点击事件
		this.$bus.$on("subMenuClick", (id, name) => {
			this.getTableData1(id);
			// console.log(name);
		})
		//监听二级菜单（文件）点击事件
		this.$bus.$on("menuItemClick", (id, name) => {
			this.isBtnShow = true;
			// console.log(name);
			this.fileName = name;
			this.fileId = id;
			this.getTableData1(id);
		})
		
	},
	methods: {
		changeFile(code) {
			// console.log(this.fileName);
			let obj = {
				code: code,
				eqId: sessionStorage.getItem("eqId"),
				deptId: sessionStorage.getItem("stationId"),
				userId: sessionStorage.getItem("userId")
			}
			// console.log(obj.code + "------" + obj.eqId + "-----" + obj.deptId + "-----" + obj.userId);
			changeFileByScan(obj).then(res => {
				this.isRight = true;
				if(res.data.code != 0) {
					this.isRight = false;
					this.remainInput =  res.data.msg;
				}
				this.remainInput =  res.data.msg;
				this.cardNoInput = "";
			})
		},
		//输入卡号时自动聚焦
		changeFocus(){
			this.$nextTick(function () {
				this.$refs.inputs.$el.querySelector('input').focus();
			});
		},
		getCardNo(e) {
			// console.log(e.target.value);
			if(e.target.value == "" || e.target.value == null) {
				return
			} 
			this.changeFile(e.target.value);
		},
		//查看文件
		openFile(fileUrl) {
			// console.log(fileUrl)
			let goUrl = this.isMobile();
			if (goUrl === 1) {
				//移动端
				window.external.Openfile(fileUrl);
			} else {
				//PC
				window.open(fileUrl);
			}
		},
		//判断Windows 移动端
		isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			);
			let goUrl = flag ? 1 : 0;
			return goUrl;
		},
		//表格隔行变色
		tableRowClassName({
			row,
			rowIndex
		}) {
			if (rowIndex % 2 === 1) {
				return 'success-row';
			}
			return '';
		},
		//获取文件夹的网络请求
		getFileDatas1(id) {
			this.dataobj = {
				fid: id,
				type: 1,
				eqId: sessionStorage.getItem("eqId"),
				deptId: sessionStorage.getItem("stationId")
			};
			getFileDatas(this.dataobj).then(res => {
				if(res.data.code !== 0)
				return this.$message.error("请求文件数据失败")
				this.treeList = res.data.data;
			})
		},
		//获取表格(文件)数据
		getTableData1(id) {
			this.dataobj = {
				fid: id,
				type: 2,
				eqId: sessionStorage.getItem("eqId"),
				deptId: sessionStorage.getItem("stationId")
			}
			getFileDatas(this.dataobj).then(res => {
				if(res.data.code !== 0)
				return this.$message.error("请求表格数据失败")
				this.tableData = res.data.data;
			})
		},
		//搜索文件
		searchFile() {
			let obj = {
				fid: this.menuId,
				filename: this.searchInput,
				type: 2
			}
			getProcessFileByFileName(obj).then(res => {
				console.log(res.data.data);
				if(res.data.code !== 0)
				return this.$message.error("搜索文件失败")
				this.tableData = res.data.data;
				this.searchInput = "";
			})
		},
		//查看按钮点击事件
		watchBtnClick(url) {
			// console.log(url)
			var fileUrl = this.baseUrl + url
			// console.log("文件地址："+fileUrl)
			this.openFile(fileUrl)
		},
		
		toggleSelection(rows) {
			if (rows) {
				rows.forEach(row => {
		            this.$refs.table1.toggleRowSelection(row);
				});
			} else {
				this.$refs.table1.clearSelection();
			}
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		confirmAdd() {
			if(this.multipleSelection && this.multipleSelection.length !== 0) {
				//console.log(this.multipleSelection.length);
				this.$bus.$emit("changeSlideFile", this.multipleSelection);
				this.$message.success("添加文件成功!");
			} else {
				this.$message.error("请选择文件!");
				//console.log(this.multipleSelection.length);
			}
		},
		backout() {
			this.$bus.$emit("getSlideFile");
			this.$message.success("操作成功!");
		}
	},
	components: {
		TreeMenu
	}
}
</script>
<style>
/* 表格隔行变色颜色 */
.el-table .success-row {
	background: rgba(244,246,247,1);
}
/*表格字体*/
.el-table {
	font-size: 1.375rem !important;
}
/*表头样式*/
.el-table th {
	background: rgba(245,247,247,1) !important;
	font-size: 1.375rem !important;
	font-family: PingFang SC !important;
	font-weight: 500 !important;
	color: rgba(58,132,171,1) !important;
}
.el-table td, .el-table th {
	height: 3.75rem !important;
	padding: 0 !important;
}
.el-table .cell {
	padding: 0.625rem 0 !important;
}
</style>
<style lang="scss" scoped>
$mainBgc: #F4F5FA;
.process-file {
	width: 100%;
	height: 100%;
	display: flex;
	background-color: $mainBgc;
	overflow: hidden;
	position: relative;
	
	.title {
		width: 100%;
		display: flex;
		padding: 0.625rem;
		.icon1, .icon2 {
			display: inline-block;
			width: 2.5rem;
			height: 2.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 0.625rem;
			margin-right: 1.25rem;
			background:linear-gradient(0deg, rgba(41,187,192,1) 0%, rgba(73,208,206,1) 100%);
			i {
				font-size: 1.375rem;
				font-weight: 500;
				color: white;
			}
		}
		.icon2 {
			background: linear-gradient(0deg,rgba(255,125,29,1) 0%,rgba(255,189,32,1) 100%);
		}
		.txt {
			font-size: 1.625rem;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(58,132,171,1);
			letter-spacing: 3px;
			line-height: 2.5rem;
		}
	}
	.input-box {
		position: absolute;
		top: 0.625rem;
		right: 0.625rem;
	}
	.container {
		width: 100%;
		height: 100%;
		border-radius: 0.625rem;
		position: relative;
		box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0,.1);
		background: white;
		.table-box {
			display: flex;
			width: 100%;
			border-top: 1px solid #EBEEF5;
			.treeList-box {
				width: 20%;
				overflow: hidden;
				border-right: 1px solid #e6e6e6;
				overflow-y: scroll;
			}
			.table {
				width: 80%;
				.table1 {
					width: 100% !important;
					overflow: hidden;
					overflow-y: scroll;
				}
			}
		}
		.table2 {
			border-top: 1px solid #eee;
			width: 100%;
			overflow: hidden;
			overflow-y: scroll;
		}
	}
}

</style>
