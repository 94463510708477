<template>
	<div class="menuTree">
      <label v-for="(item, index) in treeList">
        <el-submenu :key="item.fid" :index="item.name" v-if="item.childrenList">
          <template slot="title">
            <div class="menu-text" @click="subMenuClick(item.fid, item.name)">
              <i class="el-icon-folder-opened" style="font-size: 1.375rem;margin-right: 0.625rem;"></i>
              <span class="menu-item-text hidden" style="font-size: 1.375rem;">{{ item.name }}</span>
            </div>
          </template>
  		  <label>
  			 <tree-menu-List :treeList="item.childrenList"></tree-menu-List>
  			<!-- <Menutree ></Menutree> -->
  		  </label>
        </el-submenu>
  		<el-menu-item v-else :key="item.fid" :index="item.name">
  			<div class="menu-text" @click="menuItemClick(item.fid, item.name)">
  			    <i class="el-icon-document" style="font-size: 1.375rem;margin-right: 0.625rem;"></i>
  			    <span class="menu-item-text hidden" style="font-size: 1.375rem;">{{ item.name }}</span>
  			</div>
  		</el-menu-item>
      </label>
	</div>
</template>

<script>
export default {
  name: 'treeMenuList',
  props: {
    treeList: {
      type: Array,
      required: true
    },
    active: {
      type: String,
      default: ''
    }
  },
  methods: {
	//二级菜单点击事件
	subMenuClick(id, name) {
		this.$bus.$emit("subMenuClick", id, name);
	},
	//三级菜单点击事件
	menuItemClick(id, name) {
		this.$bus.$emit("menuItemClick", id, name);
	},
	
  }
}
</script>

<style lang="scss" scoped>
	.menuTree {
		overflow-y: scroll;
	}
</style>